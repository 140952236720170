import * as React from "react";
import Layout from "../components/Layout/Layout";
import styled from "styled-components";
import Seo from "../components/Seo";
import Data from "../utils/data";
import ReactMarkdown from "react-markdown";
const Main = styled.div`
  padding-top: 120px;
`;
const Heading = styled.h1`
  font-size: 50px;
  font-weight: 700;
`;
const SectionEntry = styled.div`
  h3 {
    font-size: 24px;
    line-height: 1.19;
    margin-top: 40px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    margin: 0;
    line-height: 26px;
  }
`;
const Terms = () => {
  const strapiGlobal = Data().strapiTermsOfService;
  return (
    <>
      <Seo
        title={strapiGlobal.seo.title}
        description={strapiGlobal.seo.description}
      />
      <Layout>

        <Main className="main">
          <div className="container">
            <div className="text-center mb-5">
              <Heading>{strapiGlobal.heading}</Heading>
            </div>

            <SectionEntry>
              <ReactMarkdown>{strapiGlobal.content.data.content}</ReactMarkdown>
            </SectionEntry>
          </div>
        </Main>
      </Layout>
    </>
  );
};

export default Terms;